import React, { useEffect, useRef, useState } from 'react';
import { Circle, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material';
import { Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';
import {
  circleColorMap,
  typeBackgroundColorMap
} from '../../MainLayout/HomeLayout/PostPage/postConstants';
import useStyles from './styles';
import PageContent from './PageContent';
import CustomButton from '../CustomButton/CustomButton';

const ReportContentCard = ({ data }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const visibleValues = isExpanded ? data.values : data.values.slice(0, 6);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.reportName]);

  return (
    <div className={classes.cardFullWidth}>
      <div className={classes.reportCardHeaderGroup}>
        <div className={classes.reportCardTitleGroup}>
          <img src="/images/elasticsearch/pdf-icon.svg" alt="pdf-icon" />
          <CustomTooltip title={isEllipsisActive ? data.reportName : ''} placement="bottom-start">
            <Link
              className={classes.cardTitleWithWrap}
              href={`/reports/${data.reportId}`}
              underline="none"
              target="_blank"
              ref={titleRef}
            >
              {data.reportName}
            </Link>
          </CustomTooltip>
        </div>
        <Grid
          className={classes.reportType}
          style={{ backgroundColor: typeBackgroundColorMap.get(data?.reportType) }}
        >
          <Circle
            className={classes.circleIcon}
            style={{ color: circleColorMap.get(data?.reportType) }}
          />
          <Typography variant="body">{data?.reportType}</Typography>
        </Grid>
      </div>
      <div className={classes.cardContent}>
        {visibleValues.map((value) => (
          <PageContent value={value} url={`/reports/${data.reportId}?contentId=${value.id}`} />
        ))}
        {data.values.length > 6 && (
          <div className={classes.cardPdfFooter}>
            <CustomButton
              onClick={toggleExpand}
              placeholder={isExpanded ? 'Hide all pages' : 'Show all pages'}
              variant="text"
              size="medium"
              textColor="secondary"
              rightIcon={isExpanded ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
              classes={classes}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ReportContentCard.propTypes = {
  data: PropTypes.object
};

export default ReportContentCard;
