import { isString, isArray } from 'lodash';
import * as constants from 'src/utils/constants/stringConstants';
import validate from 'validate.js';

const dictionary = {
  topicName: 'Topic Name'
};

export const isStateValid = (formState) =>
  !Object.keys(formState.errors).some((key) => formState.errors[key]) &&
  Object.keys(formState.touched).some((key) => formState.touched[key]);

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const validationList = (params, message) => {
  // const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]
  // [a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]
  // [a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))
  // [a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  // const regex = new RegExp(expression);
  const rules = {
    required: () => ({
      [constants.REQUIRED_FIELD]: (v) => !!v?.trim()
    }),
    customValid: (fieldName, validationFunction) => ({
      [message || `Custom message for field ${dictionary[fieldName] || fieldName}`]: (v) =>
        validationFunction(v)
    }),
    unique: (fieldName, validationFunction) => ({
      [constants.FIELD_MUST_BE_UNIQUE]: (v) => validationFunction(v?.trim())
    }),
    uniqueEmail: (validationFunction) => ({
      [constants.USER_EXIST]: (v) => validationFunction(v)
    }),
    isEmail: () => ({
      [constants.EMAIL_IS_NOT_VALID]: (email) => validate.validators.email.PATTERN.test(email)
    }),
    isValidScore: (validationFunction) => ({
      [constants.SCORE_INVALID]: (v) => validationFunction(v)
    }),
    isUrl: () => ({
      [constants.URL_IS_NOT_VALID]: (url) => isValidUrl(url)
    })
  };

  return Object.entries(params).reduce((acc, [key, value]) => {
    const message = isArray(value) ? rules[key](...value) : rules[key](value);

    return { ...acc, ...message };
  }, {});
};

/**
 * Validation of field.
 * @constructor
 * @param {object} params - The object with params validation.
 * @param {Object} params.required { required: field_name } - The rule which you need.
 * @param {string} value - The value of field.
 * @param {string} message - message for custom Error
 * @returns {string} - The error message.
 */

export const asyncFuncValidation = async (params, value, message) => {
  const rules = validationList(params, message);
  const asyncValidators = Object.keys(rules).map(async (errorMessage) => {
    try {
      const result = await rules[errorMessage](value);
      return !result && errorMessage;
    } catch (err) {
      return err.message || errorMessage;
    }
  });
  let errMessage = '';
  const results = await Promise.all(asyncValidators);
  errMessage = results.find((r) => isString(r));
  return errMessage;
};

export const funcValidation = (params, value, message) => {
  const rules = validationList(params, message);
  const results = Object.keys(rules).map((message) => !rules[message](value) && message);
  return results.find((r) => isString(r));
};

export const areInputValuesNotEqual = (defaultValue, typedValue) =>
  (!!defaultValue || !!typedValue) && defaultValue?.trim() !== typedValue?.trim();

export const areAnswersUnique = (list) => {
  const answers = list?.map((item) => item.answer.trim());
  const uniqueAnswers = new Set(answers);
  return uniqueAnswers.size === answers.length;
};
