import createReducer from '../../../utils/createReducer';
import * as constants from './elastic.constants';
import { SEARCH_PDF_CONTENT } from '../../../utils/constants/stringConstants';

const initialPagination = {
  page: 1,
  itemPerPage: 50,
  pageCounter: 0,
  itemCounter: 0
};

const initialFilters = {
  products: [],
  dealTypes: [],
  deals: [],
  currencies: [],
  reportTypes: [],
  customFieldMap: new Map(),
  selectedDates: [null, null]
};

export const initialState = {
  searchResult: [],
  loading: false,
  showFilterByProduct: true,
  availableFilters: {
    products: [],
    dealTypes: [],
    deals: [],
    currencies: [],
    reportTypes: [],
    customFieldMap: new Map()
  },
  filterValues: initialFilters,
  keyCustomFields: new Map(),
  pagination: initialPagination
};

export default createReducer(initialState, {
  [constants.GET_KEY_CUSTOM_FIELDS_SUCCESS](state, { payload }) {
    return {
      ...state,
      keyCustomFields: payload
    };
  },
  [constants.GET_SEARCH_RESULT](state) {
    return {
      ...state
    };
  },
  [constants.GET_SEARCH_RESULT_SUCCESS](state, { payload }) {
    const { searchResult, resetValues, pageCounter, itemCounter, itemsPerPage } = payload;
    return {
      ...state,
      searchResult: resetValues ? searchResult : [...state.searchResult, ...searchResult],
      pagination: {
        ...state.pagination,
        pageCounter,
        itemCounter,
        itemPerPage: itemsPerPage
      }
    };
  },
  [constants.GET_DOCUMENT_SEARCH_RESULT_SUCCESS](state, { payload }) {
    return {
      ...state,
      searchResult: state.searchResult.map((item) => {
        if (item.documentId === payload.documentId && item.cardType === SEARCH_PDF_CONTENT) {
          return payload;
        }
        return item; // Return item unchanged if it doesn't match
      })
    };
  },
  [constants.GET_UNIQUE_FILTER_VALUES_SUCCESS](state, { payload }) {
    return {
      ...state,
      availableFilters: payload
    };
  },
  [constants.REMOVE_SEARCH_RESULT_BY_TYPE](state, { payload }) {
    return {
      ...state,
      searchResult: state.searchResult.filter((item) => item.cardType !== payload)
    };
  },
  [constants.UPDATE_FILTERS](state, { payload }) {
    return {
      ...state,
      filterValues: payload
    };
  },
  [constants.CLEAR_STATE](state) {
    return {
      ...state,
      searchResult: [],
      availableFilters: {
        products: [],
        dealTypes: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map()
      },
      filterValues: initialFilters,
      pagination: initialPagination
    };
  },
  [constants.CLEAR_FILTERS](state) {
    return {
      ...state,
      filterValues: initialFilters
    };
  },
  [constants.CLEAR_SEARCH_RESULT](state) {
    return {
      ...state,
      searchResult: []
    };
  },
  [constants.SET_LOADING](state, { payload }) {
    return {
      ...state,
      loading: payload
    };
  },
  [constants.SET_SHOW_FILTER_BY_PRODUCT](state, { payload }) {
    return {
      ...state,
      showFilterByProduct: payload
    };
  },
  [constants.UPDATE_PAGINATION_PAGE](state, { payload }) {
    const { newPage } = payload;
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page: newPage
      }
    };
  }
});
