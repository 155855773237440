import * as constants from './elastic.constants';
import {
  GET_DOCUMENT_SEARCH_RESULT_SUCCESS,
  GET_UNIQUE_FILTER_VALUES_SUCCESS,
  REMOVE_SEARCH_RESULT_BY_TYPE,
  UPDATE_PAGINATION_PAGE
} from './elastic.constants';

export const getKeyCustomFieldsSuccess = (keyCustomFields) => ({
  type: constants.GET_KEY_CUSTOM_FIELDS_SUCCESS,
  payload: keyCustomFields
});

export const getSearchResult = () => ({
  type: constants.GET_SEARCH_RESULT
});

export const getSearchResultSuccess = (
  searchResult,
  resetValues,
  pageCounter,
  itemCounter,
  itemsPerPage
) => ({
  type: constants.GET_SEARCH_RESULT_SUCCESS,
  payload: {
    searchResult,
    resetValues,
    pageCounter,
    itemCounter,
    itemsPerPage
  }
});

export const setLoading = (value) => ({
  type: constants.SET_LOADING,
  payload: value
});

export const setShowFilterByProduct = (value) => ({
  type: constants.SET_SHOW_FILTER_BY_PRODUCT,
  payload: value
});

export const updateFilters = (filters) => ({
  type: constants.UPDATE_FILTERS,
  payload: filters
});

export const clearState = () => ({
  type: constants.CLEAR_STATE
});

export const clearFilters = () => ({
  type: constants.CLEAR_FILTERS
});

export const clearSearchResult = () => ({
  type: constants.CLEAR_SEARCH_RESULT
});

export const updatePaginationPage = (newPage) => ({
  type: UPDATE_PAGINATION_PAGE,
  payload: { newPage }
});

export const removeSearchResultByType = (cardType) => ({
  type: REMOVE_SEARCH_RESULT_BY_TYPE,
  payload: cardType
});

export const getUniqueFilterValuesSuccess = (data) => ({
  type: GET_UNIQUE_FILTER_VALUES_SUCCESS,
  payload: data
});

export const getDocumentSearchResultSuccess = (data) => ({
  type: GET_DOCUMENT_SEARCH_RESULT_SUCCESS,
  payload: data
});
