import { makeStyles } from '@mui/styles';
import { LAPTOP_WIDTH } from 'src/utils/constants/numericConstants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxShadow: 'none',
    borderRadius: '0px 0px 8px 8px',
    margin: '0px 24px 0px 24px'
  },
  dealsTopics: {
    backgroundColor: 'inherit'
  },
  trackChanges: {
    backgroundColor: 'inherit'
  },
  portfolios: {
    backgroundColor: 'inherit',
    margin: '0px 24px 0px 24px',
    [theme.breakpoints.down(LAPTOP_WIDTH)]: {
      margin: '0px 12px 0px 12px'
    }
  }
}));

export default useStyles;
