import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import CustomButton from 'src/components/CustomButton/CustomButton';
import CustomTextField from 'src/components/CustomTextField';
import { useDispatch } from 'react-redux';
import { validate } from 'validate.js';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { postContactUs } from 'src/services/api/email';
import { removeExtraSpaceFromObjectValues } from 'src/utils/common';
import { showAlert } from 'src/redux/actions';
import { SEND_MESSAGE } from 'src/utils/constants/stringConstants';
import AdressBlock from './AdressBlock';
import { useStyles } from './styles';
import { schema } from './schema';

const initialState = {
  values: {
    name: '',
    email: '',
    textMessage: ''
  },
  touched: {},
  errors: {}
};

export const ContactUs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [reCaptchaError, setReCaptchaError] = useState(false);
  const [formState, setFormState] = useState(initialState);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    let recaptchaToken = null;
    if (!executeRecaptcha) {
      setReCaptchaError(true);
    } else {
      recaptchaToken = await executeRecaptcha('contact_us');
    }
    return recaptchaToken;
  }, [executeRecaptcha]);

  const handleChange = ({ target }) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [target.name]: target.value
      },
      touched: {
        ...prevFormState.touched,
        [target.name]: true
      }
    }));
  };

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field]);
  const isDisabled = (field) => formState.errors[field] || !formState.values[field]?.length;

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      errors: errors || {}
    }));
  }, [formState.values]);

  const sendMessage = async () => {
    const token = await handleReCaptchaVerify();
    const data = {
      subject: formState.values.name,
      email: formState.values.email,
      body: formState.values.textMessage,
      token
    };

    try {
      const params = removeExtraSpaceFromObjectValues(data);
      await postContactUs(params);
      dispatch(showAlert({ isShown: true, type: 'success', message: SEND_MESSAGE }));
      setFormState(initialState);
    } catch (error) {
      dispatch(showAlert({ isShown: true, type: 'error', message: error?.response?.data.message }));
    }
  };

  const isSubmitDisabled =
    isDisabled('name') || isDisabled('email') || isDisabled('textMessage') || reCaptchaError;

  return (
    <Grid>
      <Typography className={classes.header}>Contact us</Typography>
      <Grid className={classes.wrapper}>
        <Grid className={classes.modalWrapper}>
          <Typography className={classes.headerModal}>
            Please fill the form to contact us
          </Typography>
          <Grid className={classes.inputFields}>
            <CustomTextField
              required
              name="email"
              error={hasError('email')}
              helperText={hasError('email') ? formState.errors.email[0] : null}
              onChange={handleChange}
              size="small"
              fullWidth
              value={formState.values.email || ''}
              className={classes.input}
              label="Your email address"
              maxLength={70}
            />
            <CustomTextField
              required
              name="name"
              error={hasError('name')}
              helperText={hasError('name') ? formState.errors.name[0] : null}
              onChange={handleChange}
              size="small"
              fullWidth
              value={formState.values.name || ''}
              className={classes.input}
              label="Name"
              maxLength={100}
            />
            <CustomTextField
              name="textMessage"
              required
              error={hasError('textMessage')}
              helperText={hasError('textMessage') ? formState.errors.textMessage[0] : null}
              onChange={handleChange}
              className={classes.textArea}
              label="Message"
              multiline
              rows={5.45}
              value={formState.values.textMessage || ''}
              variant="outlined"
              fullWidth
              maxLength={2000}
            />
          </Grid>
          <CustomButton
            classes={classes}
            fullWidth
            placeholder="Send message"
            buttonColor="black"
            disabled={isSubmitDisabled}
            onClick={sendMessage}
          />
        </Grid>
        <Grid className={classes.adressBlockWrapper}>
          <AdressBlock
            city="New York"
            country="United States"
            adress="1178 Broadway, 3rd Floor #3761, New York, NY 10001"
            number="+1 332 3319821"
          />
          <AdressBlock
            city="London"
            country="United Kingdom"
            adress="3 Waterhouse Square, 138 Holborn, London"
            number="+44 20 45388904"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
