// Answer
export const ANSWER_MAX_LENGTH = 500;
export const NOTES_MAX_LENGTH = 500;
export const DISPLAYED_TOPIC_ANSWER_LENGTH = 60;
export const MAX_PAGE_COUNT_LENGTH = 9;

// Analytics
export const TABLE_ROWS_PER_PAGE = [50, 100, 200, 500];

// Common
export const downloadRetryLimit = 10;
export const downloadRetryLimit15 = 15;

// Reports
export const MAX_REPORT_PDF_SIZE = 10485760;

// PDF
export const MAX_PDF_SELECTION_CHARACTERS = 5000;

export const SELECTION_PAGE_LIMIT = 5;

export const PAGE_OVERSCAN_COUNT = 10;

// TrialUser
export const DOWNLOAD_FILE_LIMIT = 10;

// Track Changes
export const COMPARISONS_ROWS_PER_PAGE = [10, 20, 50];

export const MAX_CONTENT_NAME_LENGTH = 1000;

// Sign Up Form
export const SIGN_UP_FORM_MAX_LENGTH = 70;

// OptionDropdown
export const DROPDOWN_INPUT_HEIGHT = 56;
export const DROPDOWN_INPUT_WIDTH = 300;
export const LIMIT_TAGS_WIDTH_BLOCK = 80;

// InternalHomePage
export const FILTER_BAR_HEIGHT = 85;

// Sidebar
export const COLLAPSED_SIDEBAR_WIDTH = 48;

// Media breakpoints
export const LAPTOP_WIDTH = 1281;

// Elasticsearch
export const DEFAULT_DOCUMENT_CONTENT_COUNT = 6;

// Favorite
export const USER_FAVORITE_LIMIT = 100;
export const USER_FAVORITE_GROUP_TOPICS_LIMIT = 50;

// Portfolio
export const IDS_MAX_LENGTH = 50;
export const MAX_AMOUNT_OF_UNIQUE_IDS = 500;
