import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bootomGroup: {
    marginTop: 'auto'
  },
  root: {
    display: 'flex',
    backgroundColor: theme.palette.white,
    padding: '0px 80px',
    boxShadow: '0px 4px 16px 0px #00000012',
    width: '100vw',
    right: 'auto',
    left: 0
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  logoutButton: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
    color: theme.palette.primary.light,
    marginRight: '40px'
  },
  logo: {
    width: 187,
    height: 30
  },
  logoInternal: {
    width: '135px',
    height: '24px'
  },
  companyName: {
    width: '100%',
    display: 'flex',
    padding: '16px 16px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  arrow: {
    padding: '10px 8px'
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    width: '135px'
  },
  list: {
    padding: '0px'
  },
  logoVisible: {
    opacity: 1,
    visibility: 'visible',
    transition: 'opacity 0.8s ease, visibility 0.3s ease',
    marginLeft: '-43px'
  },
  iconRotate: {
    transform: 'rotate(180deg)'
  },
  icon: {
    transform: 'rotate(0deg)'
  },
  hideLogo: {
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease, visibility 0.3s ease'
  },
  mainContentWrapper: {
    width: 'calc(100% - 256px)',
    transition: 'width 0.55s ease, width 0.55s ease'
  },
  mainContentWrapperClosed: {
    width: 'calc(100% - 48px)',
    transition: 'width 0.3s ease, width 0.3s ease'
  }
}));

export default useStyles;
