import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { DataGridPro, GRID_TREE_DATA_GROUPING_FIELD, useGridApiRef } from '@mui/x-data-grid-pro';
import { putPortfolioOrder } from 'src/services/api/portfolio';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { showAlert } from 'src/redux/actions';
import usePrevious from 'src/utils/usePrevious';
import { ALL_TOPICS } from 'src/MainLayout/PDFLayout/PDFTabs/TopicsTab/constants';
import useStyles from './styles';
import * as actionsCreator from '../../../context/deal.actions';
import DealContext from '../../../context/deal.context';
import { getFilteredRows, prepareColumns, prepareRows, hasRowIdsChanged } from './utils';
import { DealName } from './DealName';
import { CustomGroupingCol } from './CustomGroupingColumns';
import { slotProps } from './slotProps';

const CompareTable = ({
  leftTable,
  rightTable,
  lastDealElementRef,
  includeOnlyMajorDocuments,
  portfolio,
  getActiveDealsOrder,
  currentTab
}) => {
  const classes = useStyles();
  const {
    state: {
      expandedRows,
      portfolioDealCompareList: { deals }
    },
    dispatch
  } = useContext(DealContext);
  const api = useGridApiRef();
  const [allCategoriesExpanded, setAllCategoriesExpanded] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const prevRows = usePrevious(rows);

  const setRowsExpansion = (isExpanded) => {
    dispatch(
      actionsCreator.setExpandedRows(
        getFilteredRows(rows).map((row) => ({ id: row.id, isExpanded }))
      )
    );
  };

  const setRowExpansion = (rowId, isExpanded) => {
    dispatch(actionsCreator.setExpandedRow({ id: rowId, isExpanded }));
  };

  const handleExpand = () => {
    if (allCategoriesExpanded) {
      setRowsExpansion(false);
    } else {
      setRowsExpansion(true);
    }
    setAllCategoriesExpanded((prevAllCategoriesExpanded) => !prevAllCategoriesExpanded);
  };

  useEffect(() => {
    setRows(prepareRows(leftTable, currentTab));
    if (includeOnlyMajorDocuments) {
      const docs = rightTable.map((deal) => deal.documents.filter((doc) => doc.isMajor));
      setColumns(prepareColumns(docs, classes, lastDealElementRef, currentTab));
    } else {
      setColumns(prepareColumns(rightTable, classes, lastDealElementRef, currentTab));
    }
  }, [includeOnlyMajorDocuments, rightTable.length, deals, currentTab]);

  useEffect(() => {
    if (portfolio.id && rows.length) {
      api.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
    }
  }, [portfolio.id]);

  useEffect(() => {
    setAllCategoriesExpanded(false);
  }, [currentTab]);

  useEffect(() => {
    if (!prevRows || prevRows.length !== rows.length || hasRowIdsChanged(rows, prevRows)) {
      setRowsExpansion(false);
    }
  }, [rows]);

  const groupingColDef = {
    headerName: (
      <Grid container justifyContent="center" alignItems="center">
        <IconButton onClick={handleExpand}>
          {allCategoriesExpanded ? <UnfoldLess /> : <UnfoldMore />}
        </IconButton>
        <span>{currentTab === ALL_TOPICS ? 'Category' : 'Favorite group'}</span>
      </Grid>
    ),
    field: 'category',
    width: 200,
    minWidth: 150,
    cellClassName: ({ rowNode }) =>
      rowNode.children ? classes.categoryCol : classes.categoryColOpen,
    headerClassName: clsx(classes.categoryCol, classes.bgGray),
    hideDescendantCount: true,
    renderCell: (params) => <CustomGroupingCol {...params} setRowExpansion={setRowExpansion} />
  };

  const columnGroupingModel = () =>
    rightTable.map((deal, i) => ({
      groupId: deal.id,
      description: deal.dealName,
      headerClassName: i % 2 === 0 ? classes.bgLightGray : classes.bgGray,
      renderHeaderGroup: () => <DealName doc={deal} name={deal.dealName} />,
      children: deal.documents ? deal.documents.map((doc) => ({ field: doc.name })) : []
    }));

  const applyClasses = (elems) => {
    const overrides = elems.reduce(
      (state, el) => ({
        ...state,
        [`${el}`]: classes.bgGray
      }),
      {}
    );
    overrides.columnHeaderTitleContainer = classes.center;
    return overrides;
  };

  const dispatchRedux = useDispatch();

  const saveColumnOrder = async (state, orderedColumns) => {
    let dealsOrder = orderedColumns.map((deal) => ({
      entityId: deal.cloId,
      position: (state?.columns?.orderedFields?.indexOf(deal.dealName) ?? 2) - 1
    }));
    const activeDealsOrder = getActiveDealsOrder();
    if (dealsOrder.length !== activeDealsOrder.length) {
      dealsOrder = dealsOrder.concat(activeDealsOrder.slice(dealsOrder.length));
    }
    const params = {
      cloOrders: dealsOrder,
      portfolioId: portfolio.id
    };

    try {
      await putPortfolioOrder(params);

      dispatch(actionsCreator.updatePortfolioDealsOrder(params));
      dispatch(actionsCreator.updateDealsOrderInPortfolio(portfolio.id, params));
    } catch (error) {
      dispatchRedux(
        showAlert({ isShown: true, type: 'error', message: error?.response?.data.message })
      );
    }
  };

  const setOrderedColumns = (state) => {
    const orderedCols = [];
    state?.columns?.orderedFields?.forEach((el) => {
      rightTable.forEach((deal) => (deal.dealName === el ? orderedCols.push(deal) : null));
    });

    setColumns(prepareColumns(orderedCols, classes, lastDealElementRef, currentTab));
    saveColumnOrder(state, orderedCols);
  };
  const handleOrderChange = async () => {
    const currentState = api.current.exportState();
    setOrderedColumns(currentState);
  };

  return (
    <div className={classes.tableContainer}>
      {!!rows.length && (
        <DataGridPro
          className={classes.root}
          classes={applyClasses(['root', 'cell', 'columnHeader--emptyGroup'])}
          treeData
          apiRef={api}
          onColumnOrderChange={() => handleOrderChange()}
          disableColumnReorder={includeOnlyMajorDocuments || portfolio?.isShared}
          getTreeDataPath={(row) => row.hierarchy}
          groupingColDef={groupingColDef}
          isGroupExpandedByDefault={(group) =>
            expandedRows.find((row) => row.id === group.id)?.isExpanded
          }
          rows={rows}
          columns={columns}
          pinnedColumns={{ left: [GRID_TREE_DATA_GROUPING_FIELD, 'topics'] }}
          columnHeaderHeight={95}
          getRowHeight={({ model }) => (model.hierarchy?.length > 1 ? 95 : 60)}
          sx={slotProps}
          disableColumnMenu
          hideFooter
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel()}
        />
      )}
    </div>
  );
};

CompareTable.propTypes = {
  leftTable: PropTypes.array,
  currentTab: PropTypes.string,
  rightTable: PropTypes.array,
  lastDealElementRef: PropTypes.func,
  includeOnlyMajorDocuments: PropTypes.bool,
  portfolio: PropTypes.object,
  getActiveDealsOrder: PropTypes.func
};

export default CompareTable;
