// DEAL
export const SAVE_FILES = 'SAVE_FILES';
export const SAVE_FILES_SUCCESS = 'SAVE_FILES_SUCCESS';
export const SAVE_FILES_ERROR = 'SAVE_FILES_ERROR';

export const GET_ALL_DEALS = 'GET_ALL_DEALS';
export const GET_DEALS = 'GET_DEALS';
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS';
export const GET_DEALS_ERROR = 'GET_DEALS_ERROR';
export const LOAD_DEALS_DATA = 'LOAD_DEALS_DATA';

export const ENABLE_SHOW_DEAL_PORTFOLIO_STATUS = 'ENABLE_SHOW_DEAL_PORTFOLIO_STATUS';
export const ENABLE_SHOW_DEAL_PORTFOLIO_STATUS_TOPICS = 'ENABLE_SHOW_DEAL_PORTFOLIO_STATUS_TOPICS';

export const GET_DEAL_FILTERS_LIST = 'GET_DEAL_FILTERS_LIST';
export const GET_DEAL_FILTERS_LIST_SUCCESS = 'GET_DEAL_FILTERS_LIST_SUCCESS';

export const SEARCH_DEAL = 'SEARCH_DEAL';
export const SEARCH_DEAL_SUCCESS = 'SEARCH_DEAL_SUCCESS';
export const SEARCH_DEAL_ERROR = 'SEARCH_DEAL_ERROR';
export const DEAL_LIST_SEARCH = 'DEAL_LIST_SEARCH';

export const SELECT_OPTION_TO_SHOW_DEALS = 'SELECT_OPTION_TO_SHOW_DEALS';

export const DELETE_ALL_DEALS_FILTERS = 'DELETE_ALL_DEALS_FILTERS';
export const UPDATE_DEALS_SELECTED_FILTERS = 'UPDATE_DEALS_SELECTED_FILTERS';

export const DEAL_LIST_SCROLL = 'DEAL_LIST_SCROLL';
export const DEAL_HEADER_HEIGHT = 'DEAL_HEADER_HEIGHT';

export const UPDATE_DEALS_OPTION_FILTERS = 'UPDATE_DEALS_OPTION_FILTERS';

// PORTFOLIO
export const GET_PORTFOLIO_LIST = 'GET_PORTFOLIO_LIST';
export const GET_PORTFOLIO_LIST_SUCCESS = 'GET_PORTFOLIO_LIST_SUCCESS';
export const DELETE_PORTFOLIO_FROM_LIST = 'DELETE_PORTFOLIO_FROM_LIST';
export const EDIT_PORTFOLIO = 'EDIT_PORTFOLIO';
export const DELETE_DEAL_FROM_PORTFOLIO_LIST = 'DELETE_DEAL_FROM_PORTFOLIO_LIST';
export const ADD_PORTFOLIO = 'ADD_PORTFOLIO';
export const GET_DEALS_FOR_ADD_TO_PORTFOLIO = 'GET_DEALS_FOR_ADD_TO_PORTFOLIO';
export const GET_DEALS_FOR_ADD_TO_PORTFOLIO_SUCCESS = 'GET_DEALS_FOR_ADD_TO_PORTFOLIO_SUCCESS';
export const UPDATE_DEALS_ORDER_IN_PORTFOLIO = 'UPDATE_DEALS_ORDER_IN_PORTFOLIO';
export const UPDATE_PORTFOLIO_DEALS_ORDER = 'UPDATE_PORTFOLIO_DEALS_ORDER';

export const GET_SHARED_PORTFOLIO_LIST = 'GET_SHARED_PORTFOLIO_LIST';
export const GET_SHARED_PORTFOLIO_LIST_SUCCESS = 'GET_SHARED_PORTFOLIO_LIST_SUCCESS';
export const DELETE_SHARED_PORTFOLIO_FROM_LIST = 'DELETE_SHARED_PORTFOLIO_FROM_LIST';

// TOPICS
export const GET_TOPICS_LIST = 'GET_TOPICS_LIST';
export const GET_TOPICS_DATA = 'GET_TOPICS_DATA';
export const GET_ALL_TOPICS = 'GET_ALL_TOPICS';
export const DELETE_ALL_TOPICS_FILTERS = 'DELETE_ALL_TOPICS_FILTERS';
export const UPDATE_TOPICS_FILTERS = 'UPDATE_TOPICS_FILTERS';
export const UPDATE_TOPICS_PORTFOLIO_FILTERS = 'UPDATE_TOPICS_PORTFOLIO_FILTERS';
export const UPDATE_TOPICS_DOCUMENT_FILTERS = 'UPDATE_TOPICS_DOCUMENT_FILTERS';
export const UPDATE_TOPICS_AVAILABLE_FILTERS = 'UPDATE_TOPICS_AVAILABLE_FILTERS';
export const DOWNLOAD_TOPIC = 'DOWNLOAD_TOPIC';
export const UPDATE_TOPICS_FILTERS_LINK_OPERATOR = 'UPDATE_TOPICS_FILTERS_LINK_OPERATOR';

// Products
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const GET_UPDATED_PRODUCTS = 'GET_UPDATED_PRODUCTS';

// Draftable
export const GET_USER_COMPARISONS = 'GET_USER_COMPARISON';
export const GET_LINK_FOR_FRAME = 'GET_LINK_FOR_FRAME';
export const GET_LINK_FOR_FRAME_SUCCESS = 'GET_LINK_FOR_FRAME_SUCCESS';
export const GET_DEALS_FOR_COMPARISON = 'GET_DEALS_FOR_COMPARISON';
export const GET_DEALS_FOR_COMPARISON_SUCCESS = 'GET_DEALS_FOR_COMPARISON_SUCCESS';
export const GET_CURRENT_COMPARISON = 'GET_CURRENT_COMPARISON';

// Topics Groups
export const GET_TOPICS_GROUPS = 'GET_TOPICS_GROUPS';
export const GET_TOPICS_GROUPS_SUCCESS = 'GET_TOPICS_GROUPS_SUCCESS';

// Comparison
export const SET_EXPANDED_ROW = 'SET_EXPANDED_ROW';
export const SET_EXPANDED_ROWS = 'SET_EXPANDED_ROWS';
export const GET_PORTFOLIO_TOPICS_LIST = 'GET_PORTFOLIO_TOPICS_LIST';

// PORTFOLIO COMPARE DEAL
export const GET_DEAL_COMPARE_LIST = 'GET_DEAL_COMPARE_LIST';
export const GET_DEAL_COMPARE_LIST_SUCCESS = 'GET_DEAL_COMPARE_LIST_SUCCESS';
export const GET_DEAL_COMPARE_LIST_ERROR = 'GET_DEAL_COMPARE_LIST_ERROR';
export const UPDATE_DEAL_COMPARE_LIST_SUCCESS = 'UPDATE_DEAL_COMPARE_LIST_SUCCESS';
